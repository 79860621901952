import React, { PropsWithChildren } from "react";
import Header from "../components/Header";
import PageIllustration from "../components/PageIllustration";
import Footer from "../components/Footer";

const MainLayout: React.FC<PropsWithChildren<{ sidebar?: JSX.Element }>> = ({
  children,
}) => {
  return (
      <div className="w-full relative overflow-x-hidden z-10 sm:block bg-gray-900">
        <Header />
        <PageIllustration />
        <div className="mx-auto z-0 lg:pt-18">{children}</div>
        <Footer />
      </div>
    
  );
};

export default MainLayout;
